import React, { useEffect, useState } from 'react';
import { archetypes } from './archetypes';
import './App.css';
import Form from './Form';
import logo from './logo.svg';

function App() {
  const [selected, setSelected] = useState(null);

  return (
    <div className="App">
      <header id="header">
        <img src={logo} alt="NewSpirit" className="header-logo"/>
        <nav className="main">
          {archetypes.map((archetype, index) =>
            <a className={selected === index ? 'active' : null} key={index} href='#' onClick={() => setSelected(index)}>
              {archetype.name}
            </a>
          )}
        </nav>
        <nav>
          <a href={process.env.PUBLIC_URL + '/ns-shop.css'} download>CSS</a>
        </nav>
      </header>
      <main>
        {selected !== null ? <Form data={archetypes[selected]} /> : (
          <div>
            <h1>Vyberte kategóriu</h1>
          </div>
        )}
      </main>
    </div>
  );
}

export default App;
