import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactSlider from 'react-slider';
import { Editor } from '@tinymce/tinymce-react';

const regex = /^(\w+)(\[(\d+)-(\d+)]){0,1}$/g;

function renderField(name, type, ctx, sv) {
  const match = [...type.matchAll(regex)][0];

  function setValue(event) {
    if (match[1] === 'slider' || match[1] === 'range'){
      sv(name, event);
    } else if (match[1] === 'wysiwyg' || match[1] === 'table') {
      sv(name, event);
    } else {
      sv(name, event.target.type === 'checkbox' ? event.target.checked : event.target.value);
    }
  }

  let field = null;

  if (match[1] === 'text') {
    field = <input type="text" name={name} id={name} value={ctx[name]} onChange={setValue}/>;
  } else if (match[1] === 'textarea') {
    field = <textarea name={name} id={name} cols="30" rows="10" value={ctx[name]} onChange={setValue}></textarea>;
  } else if (match[1] === 'int') {
    field = <input type="number" name={name} id={name} value={ctx[name]} onChange={setValue} min={match[3]} max={match[4]}/>;
  } else if (match[1] === 'slider') {
    field = <ReactSlider value={ctx[name]} onChange={setValue} min={Number(match[3])} max={Number(match[4])} renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}/>;
  } else if (match[1] === 'range') {
    field = <ReactSlider className='slider range' value={ctx[name]} onChange={setValue} min={Number(match[3])} max={Number(match[4])} renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}/>;
  } else if(match[1] === 'bool') {
    field = <input type="checkbox" name={name} id={name} checked={ctx[name]} onChange={setValue}/>
  } else if(match[1] === 'wysiwyg') {
    field = <Editor
      initialValue={ctx[name]}
      init={{
        height: 300,
        menubar: true,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
          'undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat code | help'
      }}
      onEditorChange={setValue}
    />;
  } else if(match[1] === 'table') {
    field = <Editor
      initialValue={ctx[name]}
      init={{
        height: 250,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code',
          'media table paste code help'
        ],
        toolbar:
          'undo redo | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          removeformat code | help'
      }}
      onEditorChange={setValue}
    />;
  }

  return (
    <div className="field" key={name}>
      <label htmlFor={name}>{name}</label>
      {field}
    </div>
  )
}

function Form(props) {
  let initial = Object.entries(props.data.fields).reduce((obj, [k, v]) => {
    if (props.data.defaults && props.data.defaults[k]) {
      obj[k] = props.data.defaults[k];
    } else {
      obj[k] = '';
    }
    return obj;
  }, {});

  const [context, setContext] = useState(initial);
  const Template = props.data.template;

  function setValue(key, val) {
    setContext({...context, [key]: val});
  }

  return (
    <div>
      <h1>{props.data.name}</h1>
      <div className="form-top">
        <div className="entry">
          {Object.entries(props.data.fields).map(([k, v]) => renderField(k, v, context, setValue))}
        </div>
        <div className="codefield">
          <h2>Code</h2>
          <textarea name="result" id="result" cols="30" rows="10" value={ReactDOMServer.renderToStaticMarkup(<Template context={context}/>)} readOnly></textarea>
        </div>
      </div>
      <div className="preview">
        <h2 className="brule">Preview</h2>
        <Template context={context} />
      </div>
    </div>
  )
}

export default Form;
