import React from 'react';

export const test = {
    "name": "Test",
    "fields": {
        "nazov": "text",
        "description": "textarea"
    },
    "template": (props) => (
        <div>Test: {JSON.stringify(props.context)}</div>
    )
};
