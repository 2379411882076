import React from 'react';

export const snowboard = {
    "name": "Snowboardy",
    "fields": {
        "nazov": "text",
        "popis": "wysiwyg",
        "level": "range[1-3]",
        "flex": "slider[1-10]",
        "features_3bt": "bool",
        "features_sidekick": "bool",
        "features_supertubes": "bool",
        "rozmery": "table"
    },
    "defaults": {
        "level": [1,3],
        "flex": 1,
        "rozmery": `
        <table>
            <thead>
                <tr>
                    <th>Veľkosť</th>
                    <th>148</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Veľkosť viazania</td>
                    <td>XS - M</td>
                </tr>
                <tr>
                    <td>Kontaktná hrana (cm)</td>
                    <td>116,2</td>
                </tr>
                <tr>
                    <td>Efekívna hrana (cm)</td>
                    <td>115,7</td>
                </tr>
                <tr>
                    <td>Setback (cm)</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>Vykrojenie (m)</td>
                    <td>7.8</td>
                </tr>
                <tr>
                    <td>Šírka špičky / chvosta (cm)</td>
                    <td>29,1</td>
                </tr>
                <tr>
                    <td>Šírka pásu (cm)</td>
                    <td>24,9</td>
                </tr>
            </tbody>
        </table>
        `
    },
    "template": (props) => {
        const c = props.context;
        return (
            <div className="nspd-2020">
                <div dangerouslySetInnerHTML={{__html: c.popis}}></div>
                <h2>Špecifikácia</h2>
                <p>{c.level}</p>
                <p>{c.flex}</p>
                <h2>Vlastnosti</h2>
                {c.features_3bt ? <p>Triple Base Technology</p> : null}
                {c.features_sidekick ? <p>Sidekick</p> : null}
                {c.features_supertubes ? <p>Supertubes</p> : null}
                <h2>Tabuľka veľkostí</h2>
                <div dangerouslySetInnerHTML={{__html: c.rozmery}}></div>
            </div>
        )
    }
};
